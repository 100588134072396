.landcombos {
    padding: 2rem 4rem;
}

.landcombos-card-parent {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.landcombos-card {
    width: 30%;
    box-shadow: 0 0 10px #dedede;
    cursor: pointer;
}
.landcombos-card:hover {
    transform: scale(calc(1 + 0.05));
}
.landcombos-card-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.landcombos-card-title {
  padding: 1rem;
}
@media screen and (max-width:1025px){
    .landcombos {
        padding: 1rem;
    }
    .landcombos-card-parent {
       gap: 1rem;
    }
    .landcombos-card {
        width: 47%;
    }
    .landcombos-card-image img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .landcombos-card-title h4 {
        overflow: hidden;
    }
}