.footer-container {
    background-color: #DCF5FF;
    padding: 2rem 4rem 1rem 4rem;
    font-family: "Montserrat";
}
.footer-link {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
}
.footer-link ul{
    padding: 0;
}
.footer-link a {
    text-decoration: none;
    color: #00081D;
}
.footer-link h3 {
    color: #212529;
    font-size: 1.2rem;
    margin: 10px 0px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: 600;
}
.corporate li {
    color: #00081D;
    font-size: 15px;
    margin-bottom: 6px;
    /* margin-left: 18px; */
}
.services li {
    color: #00081D;
    font-size: 15px;
    margin-bottom: 6px;
    margin-left: 18px;
}
.useful-link li {
    color: #00081D;
    font-size: 15px;
    margin-bottom: 6px;
    margin-left: 18px;
}
.social {
    list-style: none;
    margin-left: 0px;
}
.twitter {
    margin: 0px 1rem;
}
.instagram {
    margin: 0px 1rem;
}

/* address and subscribe --------------  */
.address-subscribe {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.address p {
    margin: 1rem 0rem;
    color: #252129;
}
.address i {
  margin: 0;
  padding: 0;
}
.address {
    width: 50%;
}
.dubai-address p {
    font-family: "Montserrat", sans-serif;
    color: #212529;
}
.india-address {
    font-family: "Montserrat", sans-serif;
    color: #212529;
}
.india-address b {
    font-size: 1rem;
    font-weight: 800;
}

@media only screen and (max-width:1024px){
   .footer-container {
    padding: 1rem;
   }
}


.subscribe1 {
    padding-top: 1rem;
    width: 50%;
}
.subscribe1 .footer-input {
    padding: 6px 12px;
    outline: none;
    border: none;
    width: 60%;
}
.subscribe1 input::placeholder {
    color: #252129;
}
.subscribe1 button {
    background-color: #52CCFC;
    padding: 6px 12px;
    font-size: 1rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #000000;
    outline: none;
    border: none;
} 
.copyright {
    padding: 1rem 4rem;
    background-color: #DCF5FF;
}
@media only screen and (max-width:767px) and (min-width:300px){
    .address-subscribe {
       display: block;
       padding-bottom: 2rem;
    }
    .address {
       width: 100%;
    }
    .subscribe1 {
       width: 100%;
    }
    .subscribe1 input {
       width: 50%;
    }
   
    .subscribe1 button {
       font-size: 1rem;
    }
   }