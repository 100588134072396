.category-details-container {
    padding: 2rem 4rem;
}
.category-details-container h2 {
    margin-bottom: 2rem;
    font-family: "Montserrat";
    font-weight: 700;
}
.category-details-card-parent {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.category-details-card {
    width: 30%;
    box-shadow: 0 0 10px #dedede;
    cursor: pointer;
}
@media screen and (min-width:300px) and (max-width:768px) {
    .category-details-container {
        padding: 1rem;
    }
    .category-details-card {
        width: 95%;
    }
}
.category-details-image img {
    max-width: 100%;
    max-height: 100%;
}
.category-details {
    padding: 1rem 0 1rem 1rem;
}
.category-details h4 {
    max-height: 50px;
    min-height: 50px;
}