
.popup-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 1000;
}  

.popupformtop {
    display: flex;
    justify-content: space-between;
    padding:1rem 2rem;
}
.popupformtop h5 {
    color: #00081d;
    padding-right: 4rem;
    margin-top: 1rem;
    font-weight: 700;
}
.popup-icon {
    cursor: pointer;
    font-size: 2rem;
}
  .form-container {
    padding: 0 2rem;
  }
  .form {
    display: block;
    width: 100%;
  }
  .form input {
    color: #00081D;
  }
  .form::placeholder {
    color: #00081D;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  
 .form-group label {
    display: block;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
.form-group input {
    padding: 0.5rem;
  }

.form-group input,textarea,select {
    width: 100%;
    border-radius: 2rem;
    height: 30px;
    border: 1.5px solid skyblue;
    outline: 1.5px solid skyblue;
  }
  .email-mobile {
    display: flex; 
    gap: 2rem;
    padding-top: 3px;

  }
#packageName {
  outline: none;
  border: 1px solid skyblue;
}  
#city {
    height: 30px;
    border-radius: 2rem;
    width: 100%;
    padding: 0 1rem;
} 
#dateoftravel {
    padding: 0 1rem;
    height: 30px;
}
.people-count {
    display: flex;
    gap: 3rem;
    margin-bottom: 1rem;
    margin-top: 8px;
} 
.desktop-query {
  line-height: 1.5rem;
  margin-bottom: 0;
  width: 90%;
  font-weight: 600;
  margin-top: 1rem;
}

.mobile-query {
  display: none;
 }
 .departure-city {
  overflow: hidden;
 }
 .adults-count-label {
  margin-bottom: 3px;
  overflow: hidden;
  font-size: 14px;
 }
 .adult b {
  border: 1px solid #ccc;
  padding: 3px 5px 4px;
  /* min-width:10px;
  max-width: 10px; */
}
.dec-btn {
  border: none;
  outline: none;
  background-color: #52CCFC;
  padding: 2px 10px;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.inc-btn {
  border: none;
  outline: none;
  background-color: #52CCFC;
  padding: 2px 10px;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.form-group-textarea {
  height: 4rem;
  padding: 0 0 0.5rem 0.5rem ;
  border: 1.5px solid skyblue;
}
.submit-button {
  background-color: #00081D;
  color: #4DC0EE;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}


@media screen and (max-width:1024px){
  .popup-form {
    width: 60%;
  }
} 
@media screen and (min-width:300px) and (max-width:769px) {
     .popupformtop {
      padding:0rem 1rem;
     }
      .popup-form {
        width: 100%;
      }
      .form-container {
        padding:0rem 1rem;
      }
     .desktop-query {
      display: none;
     }
     .mobile-query {
      display: block;
     }
     .people-count {
      text-align: center;
      display: flex;
     }
     .adults-count {
      width: 40%;
     }
     .form-group-textarea {
      margin: 0;
      height: 2rem;
     }
     
     .adult b {
      padding:0.52rem 5px;
     }
}

/* ---------------------------- */
.form-group .react-tel-input .form-control {
  width: fit-content;
  border-radius: 2rem;
  border: 1.5px solid skyblue;
  outline: 1.5px solid skyblue;
}
.form-group .form-control:focus {
  border: 1.5px solid skyblue;
}
.form-group .react-tel-input .flag-dropdown {
   border-top-left-radius: 20px;
   border-bottom-left-radius: 20px;
}