.super-sell-container {
    padding: 2rem 4rem;
    font-family: "Montserrat";
}
.super-sell-crousel {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}
.super-sell {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    gap: 1rem;
}
.super-sell-card {
    box-shadow: 0 0 10px #dedede;
    width: 32%;
    transition: transform 0.3s ease;
    cursor: pointer; 
}
.super-sell-card:hover {
    transform: scale(calc(1 + 0.05));
}
@media screen and (min-width: 300px) and (max-width: 767px) {
    .super-sell-container {
        padding: 1.5rem;
    }
    .super-sell-crousel {
        padding: 1rem;
    }
    .super-sell-container button {
        display: none;
    }
    .super-sell {
        flex-wrap: wrap;
    }
    .super-sell-card {
        width: 90%;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .super-sell-container {
        padding: 1rem;
    }
    .super-sell-card {
        width: 31%;
    }
}
.super-sell-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.super-sell-card-details {
    padding: 0.5rem 1rem 1rem 1.5rem;
    cursor: pointer;
}
.super-sell-card-details h3 {
    font-family: "Montserrat";
    min-height: 50px;
    max-height: 55px;
    overflow: hidden;
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
}
.super-sell-card-details span,p {
    margin: 0;
    padding: 0;
}
.super-sell-rating-icon {
    display: flex;
    text-align: center;
    height: 1.4rem;
}
.supercell-price-arrow {
    display: flex;
    justify-content: space-between;
}
.supercell-price-arrow b {
    color: #248900;
    font-size: 1.3em;
}
.supersell-btn {
    border: none;
    outline: none;
    padding: 0px 4px;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 4px;
    color: rgb(83, 68, 68);
    background-color: #e2e0e0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

}
